import { api } from './api';
import { sepaMandateToServerRequest } from './mappers/sepaMandateMapper';

export const createSepaMandate = async (quoteId: string | number, iban: string): Promise<void> => {
  const requestBody = sepaMandateToServerRequest({ iban });

  await api(`api/v1/quotes/${quoteId}/sepa-mandate`, {
    method: 'POST',
    body: requestBody,
  });
};
