import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { type BillingInformation, getBillingInformation } from '../apis/getBillingInformation';
import { useToast } from '../components/Toast/useToast';
import { useSubscriptionFsmState } from '../fsm/context';

export const useBillingInformation = (): BillingInformation | undefined => {
  const { openToast } = useToast();

  const {
    context: { tarificationResult },
  } = useSubscriptionFsmState();

  const [billingData, setBillingData] = useState<BillingInformation>();

  const fetchBillingData = useCallback(async () => {
    try {
      const data = await getBillingInformation(tarificationResult?.quoteId ?? '');
      setBillingData(data);
    } catch (error) {
      Sentry.captureException(error);

      openToast({
        message: 'Une erreur est survenue',
        timer: 5000,
        type: 'error',
        error: error as Error,
      });
    }
  }, [tarificationResult?.quoteId, openToast]);

  useEffect(() => {
    fetchBillingData();
  }, [fetchBillingData]);

  return billingData;
};
