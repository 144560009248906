import { api } from './api';
import { ibanValidationToServerRequest } from './mappers/ibanValidationMapper';
import type { ServerIbanValidationResponse } from './types/IbanValidation';

export const validateIban = async (iban: string): Promise<boolean> => {
  const requestBody = ibanValidationToServerRequest({ iban });

  const { isValid } = await api<ServerIbanValidationResponse>('api/v1/quotes/bank-accounts/validate', {
    method: 'POST',
    body: requestBody,
  });

  return isValid;
};
