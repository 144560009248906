import { ErrorTypesEnum } from '@ornikar/api-helpers';
import { api } from './api';

interface ValidationItem {
  valid: boolean;
  report?: string[];
}

interface ValidateContactServerResponse {
  phone: ValidationItem;
  email: ValidationItem;
}

interface ValidateContactBadServerResponse {
  statusCode: number;
  message: string[];
  error: string;
}

interface ValidateContactResponse {
  phoneReport: ValidationItem;
  emailReport: ValidationItem;
}

const PHONE_NUMBER_GENERIC_ERROR = 'Veuillez entrer un numéro de téléphone valide.';
const EMAIL_ADDRESS_GENERIC_ERROR = 'Veuillez renseigner une adresse email valide.';

export const CONTACT_VALIDATION_GENERIC_DEFAULT_ERROR = 'genericDefaultError';

export async function validateContact(phoneNumber: string, emailAddress: string): Promise<ValidateContactResponse> {
  const searchParams = new URLSearchParams({ phoneNumber, emailAddress });

  try {
    const response = await api<ValidateContactServerResponse | ValidateContactBadServerResponse>(
      `contact/validate?${searchParams.toString()}`,
    );

    const { phone: phoneReport, email: emailReport } = response as ValidateContactServerResponse;

    return {
      phoneReport,
      emailReport,
    };
  } catch (error: any) {
    if (error.name === ErrorTypesEnum.HttpError && error.status === 400 && error.body) {
      const errorMessages = (error.body as ValidateContactBadServerResponse).message;

      const isPhoneOk = !errorMessages.some((message) => message.includes('phoneNumber'));
      const isEmailOk = !errorMessages.some((message) => message.includes('emailAddress'));

      return {
        phoneReport: {
          valid: isPhoneOk,
          report: !isPhoneOk ? [PHONE_NUMBER_GENERIC_ERROR] : undefined,
        },
        emailReport: {
          valid: isEmailOk,
          report: !isEmailOk ? [EMAIL_ADDRESS_GENERIC_ERROR] : undefined,
        },
      };
    }

    throw error;
  }
}
