import type { HighlightProps as KittHighlightProps } from '@ornikar/kitt';
import cx from 'classnames';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

interface HighlightProps extends KittHighlightProps {}

export function Highlight({ dark, children }: HighlightProps): ReactNode {
  return <div className={cx(styles.Highlight, { [styles.Dark]: dark })}>{children}</div>;
}
