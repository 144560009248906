import { FORCE_IS_ALLIANZ_CONTRACT } from '../../constants/flagshipKeys';
import { useFsFlag } from '../../setup/flagship';

export const useForceIsAllianzContract = (): boolean => {
  const forceIsAllianzContractFlag = useFsFlag<boolean>(FORCE_IS_ALLIANZ_CONTRACT, false, {
    shouldSendVariationToMixpanel: false,
  });

  return forceIsAllianzContractFlag.getValue();
};
