import { useComposeValidators, useDateValidator, useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import {
  useDateAfterNYearsAgoValidator,
  useDateBeforeTomorrowValidator,
} from '../../../forms/validation/sharedValidators';
import { WithdrawalType } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import type { Driver } from '../../../fsm/types';
import { useForceIsAllianzContract } from '../../../hooks/flags/useForceIsAllianzContract';
import type { DriverScreenProps } from '../../types/DriverScreenProps';

interface CancellationOrSuspensionRecordDateScreenProps extends DriverScreenProps {
  linkedToATermination?: boolean;
}

const useCancellationOrSuspensionRecordDateValidator = (isForcedAllianzContract?: boolean) =>
  useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    // @TODO : Remove the "new Date() >= new Date(2024, 9, 1)" condition when reaching the 1st October 2024
    useDateAfterNYearsAgoValidator(new Date() >= new Date(2024, 9, 1) || isForcedAllianzContract ? 3 : 5),
    useDateBeforeTomorrowValidator(),
  );

export function CancellationOrSuspensionRecordDateScreen({
  driver,
  linkedToATermination,
}: CancellationOrSuspensionRecordDateScreenProps): ReactNode {
  const isForcedAllianzContract = useForceIsAllianzContract();
  const validator = useCancellationOrSuspensionRecordDateValidator(isForcedAllianzContract);
  const driverKey: `${Driver}Driver` = `${driver}Driver`;

  const {
    context: {
      answers: { [driverKey]: driverAnswers },
      editingInsuranceRecord: { index },
    },
  } = useSubscriptionFsmState();

  const withdrawalType = linkedToATermination
    ? driverAnswers?.terminationRecords?.[0].cancellationOrSuspensionRecord?.withdrawalType
    : driverAnswers?.cancellationOrSuspensionRecords?.[index].withdrawalType;

  const fieldName = linkedToATermination
    ? `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.date`
    : `${driver}Driver.cancellationOrSuspensionRecords[${index}].date`;

  return (
    <ScreenTemplateWithValidation
      title={`A quelle date cette ${
        withdrawalType === WithdrawalType.Cancellation ? 'annulation' : 'suspension'
      } est-elle intervenue\u00A0?`}
      fieldNames={[fieldName]}
      highlightTitle="Où trouver cette date&nbsp;?"
      highlightContent="Cette information se trouve sur votre Relevé d’Information."
    >
      <DateField name={fieldName} validate={validator} />
    </ScreenTemplateWithValidation>
  );
}
