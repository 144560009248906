import type { ApiCallOptions } from '@ornikar/api-helpers';
import { apiCall } from '@ornikar/api-helpers';

export const api = <Result>(url: string, options?: ApiCallOptions): Promise<Result> => {
  const leadId = localStorage.getItem('leadId');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return apiCall<Result>(`${import.meta.env.VITE_INSURANCE_API_URL}/${url}`, {
    ...(options as any),
    shouldAllowEmptyResponse: true,
    headers: {
      ...options?.headers,
      'X-Session-Id': leadId,
    },
  });
};
