import { cancellationOrSuspensionReasonOptionsDict } from '../../../constants/mappers/information';
import type { CancellationOrSuspensionRecord, DriverAnswers } from '../../../fsm/answers';
import {
  AccidentType,
  DriverHasBeenCovered,
  InsuranceRecordType,
  UnderTheInfluenceOf,
  WithdrawalType,
} from '../../../fsm/answers';
import { formatDateForUI } from '../../../utils/date';

export const underTheInfluenceOfSummaryDict = {
  [UnderTheInfluenceOf.None]: 'Non',
  [UnderTheInfluenceOf.Alcohol]: 'D’alcool',
  [UnderTheInfluenceOf.Drugs]: 'De stupéfiants',
  [UnderTheInfluenceOf.AlcoholAndDrugs]: 'D’alcool et de stupéfiants',
};

export const generateHasBeenCoveredText = (driver: DriverAnswers, fromDate: string): string => {
  const { hasBeenCovered, insurancePeriods } = driver;

  if (DriverHasBeenCovered.NonJamais === hasBeenCovered) {
    return 'Non jamais';
  }

  if (DriverHasBeenCovered.NonSurPeriode === hasBeenCovered) {
    return 'Pas sur cette période';
  }

  if (DriverHasBeenCovered.OuiPartiellement === hasBeenCovered) {
    const periodsText = insurancePeriods
      ?.map(({ start, end }) => `du ${formatDateForUI(start)} au ${formatDateForUI(end)}`)
      .join(',\n');

    return `Assuré ${periodsText}`;
  }

  return `Assuré en continu depuis le ${fromDate}`;
};

export const generateSectionTitle = (sectionType: InsuranceRecordType, recordCount: number): string => {
  const pluralSuffix = recordCount > 1 ? 's' : '';

  if (sectionType === InsuranceRecordType.CancellationOrSuspension) {
    return `${recordCount} suspension${pluralSuffix} ou annulation${pluralSuffix} de permis`;
  }

  return `${recordCount} déclaration${pluralSuffix} de sinistre`;
};

export const generateCancellationOrSuspensionCardTitle = (
  cancellationOrSuspension: CancellationOrSuspensionRecord,
): string => {
  const { withdrawalType, claimRecord } = cancellationOrSuspension;

  let title = withdrawalType === WithdrawalType.Cancellation ? 'Annulation' : 'Suspension';

  if (claimRecord?.date) {
    title += ` du ${formatDateForUI(claimRecord?.date)}`;
  }

  return title;
};

export const getCancellationOrSuspensionReason = (cancellationOrSuspension: CancellationOrSuspensionRecord): string => {
  const accident =
    cancellationOrSuspension.accident === AccidentType.MaterialAccident ? 'Accident matériel' : 'Accident corporel';

  return cancellationOrSuspension.reason
    ? cancellationOrSuspensionReasonOptionsDict[cancellationOrSuspension.reason]
    : accident;
};
