import { Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Highlight } from './components/Highlight';

const highlightItems = [
  {
    title: 'Quels sont les documents que je dois transmettre pour valider mon contrat\u00A0?',
    content: (
      <>
        Afin de valider votre contrat et obtenir votre carte verte définitive, vous devrez transmettre en ligne, via
        votre Espace Client, ces documents&nbsp;:
        <ul>
          <li>Permis de conduire.</li>
          <li>Certificat d’immatriculation définitif ou provisoire.</li>
          <li>Relevé d’informations des 36 derniers mois.</li>
        </ul>
        Ces documents peuvent varier en fonction de votre situation.
      </>
    ),
  },
  {
    title: 'Qu’est ce que le Relevé d’informations, comment le récupérer et le transmettre\u00A0?',
    content: (
      <>
        Le Relevé d’information vous est transmis automatiquement quand vous résiliez un contrat d’assurance. Il est
        aussi possible d’en faire la demande auprès de votre assureur. Vous devrez ensuite le transmettre via votre
        Espace Client Ornikar Assurance.
      </>
    ),
  },
  {
    title: 'Qu’est-ce qu’une franchise et comment est-elle calculée\u00A0?',
    content: (
      <>
        Une franchise, c’est le montant déduit de votre remboursement en cas de sinistre. Si une franchise est de 75€ et
        que les dommages à réparer sont de 375€, vous êtes remboursé de 300€ et vous payez 75€.
        <br />
        <br />
        La franchise, comme le prix de votre assurance auto, peut varier selon certains critères comme l'ancienneté de
        votre véhicule, sa puissance fiscale, votre localisation, etc.
      </>
    ),
  },
  {
    title: 'Pourquoi m’assurer que j’ai déclaré des informations correctes\u00A0?',
    content: (
      <>
        Votre tarif est calculé en fonction des informations que vous transmettez et qui sont vérifiées par nos
        services. La validation de votre contrat d’assurance et -&nbsp;par la suite&nbsp;- votre indemnisation en cas de
        sinistre dépendront de leur exactitude. Gagnez du temps en vous assurant dès à présent d’avoir fourni des
        informations justes.
      </>
    ),
  },
];

export function FrequentlyAskedQuestions(): ReactNode {
  return (
    <VStack space="kitt.6">
      <Typography.Header3 base="header3">Vos questions sur l’assurance Ornikar, nos réponses.</Typography.Header3>
      <VStack space="kitt.4">
        {highlightItems.map((highlight) => (
          <Highlight key={highlight.title} title={highlight.title}>
            <Typography.Paragraph base="body" color="black-anthracite">
              {highlight.content}
            </Typography.Paragraph>
          </Highlight>
        ))}
      </VStack>
    </VStack>
  );
}
