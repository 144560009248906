import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { createValidDateAfterValidator, createValidDateBeforeValidator } from '@ornikar/validators';
import { addDays, addMonths, format, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { defineMessages } from 'react-intl';
import { HamonWantType } from '../../../../apis/types/LongQuoteAnswers';
import { HAMON_DELAY_DAYS, MONTH_INTERVAL_MAX, SUBSCRIPTION_MAX_DATE } from '../../../../constants/coverageDate';
import { useIsSubscriptionMaxDateEnabled } from '../../../../hooks/flags/useIsSubscriptionMaxDateEnabled';
import { validatorMessages } from '../../messages';

const messages = defineMessages({
  validDateAfter: {
    id: 'VehicleCoverageDate.validDateAfterMessage',
    defaultMessage: "La date de début d'assurance doit être postérieure à la date d'achat du véhicule ({date}).",
  },
  validDateAfterNow: {
    id: 'VehicleCoverageDate.validDateAfterNowMessage',
    defaultMessage: "Votre assurance ne peut prendre effet qu'à partir d'aujourd'hui.",
  },
  validDateBeforeMonthIntervalMaxDate: {
    id: 'VehicleCoverageDate.validDateBeforeMonthIntervalMaxDateMessage',
    defaultMessage: "La date de début d'assurance doit survenir dans les {maxMonth} prochains mois",
  },
  validDateBeforeSubscriptionMaxDate: {
    id: 'VehicleCoverageDate.validDateBeforeSubscriptionMaxDateMessage',
    defaultMessage: "La date de début d'assurance ne peut pas survenir après le {date}",
  },
  validDateAfterHamon: {
    id: 'VehicleCoverageDate.validDateAfterHamonMessage',
    defaultMessage: `La date de début d'assurance ne peut pas survenir avant ${HAMON_DELAY_DAYS} jours.`,
  },
});

const useDateAfterNowValidator = (): IntlValidator<string> =>
  useValidator(
    createValidDateAfterValidator(() => format(subDays(new Date(), 1), 'yyyy-MM-dd')),
    messages.validDateAfterNow,
  );

const useDateAfterValidator = (vehiculeAcquisition: string): IntlValidator<string> =>
  useValidator(
    createValidDateAfterValidator(() => format(subDays(new Date(vehiculeAcquisition), 1), 'yyyy-MM-dd')),
    messages.validDateAfter,
    { date: format(new Date(vehiculeAcquisition), 'dd/MM/yyyy') },
  );

const useDateBeforeMonthIntervalMaxDateValidator = (): IntlValidator<string> => {
  const monthIntervalMaxDate = addMonths(new Date(), MONTH_INTERVAL_MAX);

  return useValidator(
    createValidDateBeforeValidator(() => format(addDays(monthIntervalMaxDate, 1), 'yyyy-MM-dd')),
    messages.validDateBeforeMonthIntervalMaxDate,
    { maxMonth: MONTH_INTERVAL_MAX },
  );
};

const useDateBeforeSubscriptionMaxDateValidator = (): IntlValidator<string> =>
  useValidator(
    createValidDateBeforeValidator(() => format(addDays(SUBSCRIPTION_MAX_DATE, 1), 'yyyy-MM-dd')),
    messages.validDateBeforeSubscriptionMaxDate,
    { date: format(SUBSCRIPTION_MAX_DATE, 'd MMMM yyyy', { locale: fr }) },
  );

const useDateAfterHamonDateValidator = (): IntlValidator<string> =>
  useValidator(
    createValidDateAfterValidator(() => format(addDays(new Date(), HAMON_DELAY_DAYS - 1), 'yyyy-MM-dd')),
    messages.validDateAfterHamon,
  );

export const useVehiculeCoverageDateValidator = (
  vehiculeAcquisition: string,
  hamonWant?: HamonWantType,
): IntlValidator<string> => {
  const isSubscriptionMaxDateEnabled = useIsSubscriptionMaxDateEnabled();

  const validators: IntlValidator<string, Record<string, unknown>>[] = [
    useRequiredValidator(),
    useDateValidator(validatorMessages.dateFormatValidator),
    useDateAfterValidator(vehiculeAcquisition),
    useDateAfterNowValidator(),
    useDateBeforeMonthIntervalMaxDateValidator(),
  ];

  const dateBeforeSubscriptionMaxDateValidator = useDateBeforeSubscriptionMaxDateValidator();
  const dateAfterHamonDateValidator = useDateAfterHamonDateValidator();

  if (isSubscriptionMaxDateEnabled) {
    validators.push(dateBeforeSubscriptionMaxDateValidator);
  }

  if (hamonWant === HamonWantType.Yes) {
    validators.push(dateAfterHamonDateValidator);
  }

  return useComposeValidators(...validators);
};
