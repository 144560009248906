import type { LongQuoteAnswers } from '../fsm/answers';
import { api } from './api';
import {
  mapAnswersAddonsToQuoteOptionsAddons,
  mapAnswersDeductiblesToQuoteOptionsDeductibles,
} from './mappers/optionsMapper';

export const sendQuoteOptions = async (
  quoteId: string | number,
  answers: Partial<LongQuoteAnswers>,
): Promise<string | number> => {
  await api(`api/v1/quotes/${quoteId}/options`, {
    method: 'POST',
    body: {
      addOns: mapAnswersAddonsToQuoteOptionsAddons(answers.addons),
      deductibles: mapAnswersDeductiblesToQuoteOptionsDeductibles(answers.deductibles),
    },
  });

  return quoteId;
};
