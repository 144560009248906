import { addMonths, min } from 'date-fns';
import { useMemo } from 'react';
import { MONTH_INTERVAL_MAX, SUBSCRIPTION_MAX_DATE } from '../../../constants/coverageDate';
import { useIsSubscriptionMaxDateEnabled } from '../../../hooks/flags/useIsSubscriptionMaxDateEnabled';

export const useVehicleCoverageMaxDate = (): Date => {
  const isSubscriptionMaxDateEnabled = useIsSubscriptionMaxDateEnabled();

  return useMemo(() => {
    const vehicleCoverageMaxDate = addMonths(new Date(), MONTH_INTERVAL_MAX);

    if (isSubscriptionMaxDateEnabled) {
      return min([vehicleCoverageMaxDate, SUBSCRIPTION_MAX_DATE]);
    }

    return vehicleCoverageMaxDate;
  }, [isSubscriptionMaxDateEnabled]);
};
