import { Button, Highlight, Modal } from '@ornikar/kitt';
import { Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useContactModal, useSetContactModal } from '../../contexts/contactModal';
import { useSubscriptionFsmState } from '../../fsm/context';
import { State } from '../../fsm/types';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { CallbackModuleModal } from '../CallbackModuleModal';
import styles from './styles.module.css';

const isPostQuote = (state: State): boolean =>
  [
    State.FORMULES,
    State.HAMON_LAW_INFORMATION,
    State.REGISTER_IBAN,
    State.CONFIRM_SEPA_MANDATE,
    State.CREDIT_CARD_PAYMENT,
    State.VEHICLE_LEGAL_INFORMATION,
    State.LRE_INFORMATION,
    State.WEBAPP_INTRODUCTION,
  ].includes(state);

export function ContactModal(): ReactNode {
  const visible = useContactModal();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const setContactModal = useSetContactModal();
  const isDesktop = useMediaQuery(Query.MEDIUM);
  const { value: currentState } = useSubscriptionFsmState();

  return (
    <>
      <Modal visible={visible} onClose={() => setContactModal(false)}>
        <Modal.Header>
          <Typography.Text variant="bold">Contact</Typography.Text>
        </Modal.Header>
        <Modal.Body className={styles.ModalBody}>
          <View marginBottom={isDesktop ? 12 : 24}>
            <Typography.Text base="header4">Un doute&nbsp;? Une question&nbsp;? Bavardons&nbsp;!</Typography.Text>
          </View>
          <Highlight>
            <div className={styles.Highlight}>
              <p className={styles.Explanation}>
                Sophie, Mamadou et tous nos conseiller•e•s sont à votre disposition du lundi au samedi entre 10h et 19h.
              </p>
              <div className={styles.ButtonsContainer}>
                <Button
                  stretch={!isDesktop}
                  type="primary"
                  icon="phone"
                  href="tel:0176497522"
                  className={styles.Button}
                >
                  01 76 49 75 22
                </Button>
                {isPostQuote(currentState as State) && (
                  <Button
                    stretch={!isDesktop}
                    type="primary"
                    icon="calendar"
                    className={styles.Button}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Prendre un rendez-vous
                  </Button>
                )}
                <Button
                  stretch={!isDesktop}
                  type="primary"
                  icon="send"
                  className={styles.Button}
                  href="mailto:assurance@ornikar.com"
                >
                  assurance@ornikar.com
                </Button>
              </div>
            </div>
          </Highlight>
        </Modal.Body>
      </Modal>
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </>
  );
}
