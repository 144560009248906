import * as Sentry from '@sentry/react';
import { api } from './api';

export const sendAgentEmail = async (agentEmail: string, quoteId: number): Promise<void> => {
  try {
    await api(`api/v1/quotes/${quoteId}/sales-agent`, {
      method: 'PATCH',
      body: { email: agentEmail },
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
