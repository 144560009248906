import { Input } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { Field } from '../../../components/Field';
import { Row } from '../../../components/Row';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useZipcodeValidator } from '../../../forms/validation/sections/informations/zipcode';
import { CommunesField } from './CommunesField';
import styles from './styles.module.css';

export function DrivingCityScreen(): ReactNode {
  const { change, blur } = useForm();
  const zipcodeValidator = useZipcodeValidator();

  return (
    <ScreenTemplateWithValidation
      fieldNames={['parkingCommune']}
      title="Dans quelle ville&nbsp;?"
      highlightTitle="Notre conseil d'assureur"
      highlightContent={
        <div>
          Certaines régions sont plus touchées par le vol de voiture.
          <br />
          <br />
          Pour bien vous protéger contre le vol de votre véhicule, la garantie &quot;Vol&quot; est fortement
          recommandée.
        </div>
      }
    >
      <Row>
        <Field
          autoFocus
          className={styles.Field}
          component={Input}
          name="parkingCodePostal"
          label="Code postal"
          validate={zipcodeValidator}
          onChange={(value: string) => {
            change('parkingCommune', undefined);
            if (value.length === 5) {
              // trigger validation for communesField to get communes from api
              setTimeout(() => blur('parkingCodePostal'), 0);
            }
          }}
        />
        <CommunesField />
      </Row>
    </ScreenTemplateWithValidation>
  );
}
