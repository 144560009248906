import { ENABLE_SUBSCRIPTION_MAX_DATE } from '../../constants/flagshipKeys';
import { useFsFlag } from '../../setup/flagship';

export const useIsSubscriptionMaxDateEnabled = (): boolean => {
  const enableSubscriptionMaxDateFlag = useFsFlag<boolean>(ENABLE_SUBSCRIPTION_MAX_DATE, false, {
    shouldSendVariationToMixpanel: true,
  });

  return enableSubscriptionMaxDateFlag.getValue();
};
