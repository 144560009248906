import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import type { FormValues } from '../forms/answers';

export const sendEvent = (eventName: string, properties?: Dict): void => {
  const leadId = localStorage.getItem('leadId');
  const insuranceSubscriptionProps = mixpanel.get_property('insurance_subscription');

  mixpanel.track(`Insurance - ${eventName}`, {
    insurance_subscription: {
      lead_id: leadId,
      ...insuranceSubscriptionProps,
      ...properties,
    },
  });
};

export const sendNoOptionsFoundEvent = (fieldName: keyof FormValues): void => {
  sendEvent('No Options Found', {
    field_name: fieldName,
  });
};
