import { CaretDownRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Pressable, Typography, TypographyIcon, VStack, View, useTheme } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect, useRef, useState } from 'react';

interface HighlightProps {
  title: string;
  children: ReactNode;
}

export function Highlight({ title, children }: HighlightProps): ReactNode {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);
  const contentMargin = theme.kitt.getSpacing(2);

  useEffect(() => {
    const handleResize = (): void => setContentHeight(contentRef.current?.clientHeight ?? 0);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight ?? 0);
  }, [contentRef.current?.clientHeight]);

  const handlePress = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleHoverIn = (): void => {
    setIsHovered(true);
  };

  const handleHoverOut = (): void => {
    setIsHovered(false);
  };

  const containerAnimation = {
    backgroundColor: isHovered ? theme.kitt.palettes.lateOcean.black50 : theme.kitt.palettes.lateOcean.white,
    transitionDuration: '300ms',
    transitionProperty: 'background-color',
    transitionTimingFunction: 'ease',
  };

  const chevronAnimation = {
    transform: `rotate(${isExpanded ? '180deg' : '0deg'}) translateY(${isHovered ? '2px' : '0'})`,
    transitionDuration: '300ms',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease',
  };

  const contentAnimation = {
    height: isExpanded ? `${contentHeight + contentMargin}px` : '0',
    transitionDuration: '300ms',
    transitionProperty: 'height',
    transitionTimingFunction: 'ease',
  };

  return (
    <Pressable onPress={handlePress} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
      <VStack padding="kitt.4" borderRadius="kitt.2" style={containerAnimation}>
        <HStack space="kitt.2" alignItems="center" justifyContent="space-between">
          <View flexGrow={1} flexShrink={1}>
            <Typography.Text variant="bold" base="body">
              {title}
            </Typography.Text>
          </View>
          <View style={chevronAnimation}>
            <TypographyIcon icon={<CaretDownRegularIcon />} />
          </View>
        </HStack>
        <View style={[{ overflow: 'hidden' }, contentAnimation]}>
          <View ref={contentRef} marginTop={contentMargin}>
            {children}
          </View>
        </View>
      </VStack>
    </Pressable>
  );
}
